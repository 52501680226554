
export enum ServerAction {
  UNKNOWN_ACTION = 'Unknown',

  SAP_CONCUR_REQUEST = 'SapConcur',
  RECAPTCHA_REQUEST = 'Recaptcha',
  GREENCOM_REQUEST = 'Greencom',
  ECOWATT_REQUEST = 'Ecowatt',
  STRIPE_REQUEST = 'Stripe',
  IOTHINK_REQUEST = 'IOThink',
  LACROIX_REQUEST = 'Lacroix',
  EV_DATABASE_REQUEST = 'EVDatabase',
  WIT_REQUEST = 'Wit',
  SAP_SMART_CHARGING_REQUEST = 'SapSmartCharging',

  SIGN_IN = 'SignIn',
  SIGN_OUT = 'SignOut',
  SIGN_ON = 'SignOn',
  REGISTER_GUEST_USER = 'RegisterGuestUser',
  PASSWORD_RESET = 'PasswordReset',
  CHECK_CONNECTION = 'CheckConnection',

  OCPP_RESET = 'OcppReset',
  OCPP_CLEAR_CACHE = 'OcppClearCache',
  OCPP_GET_CONFIGURATION = 'OcppGetConfiguration',
  OCPP_GET_VARIABLE = 'OcppGetVariable',
  OCPP_SET_VARIABLE = 'OcppSetVariable',
  OCPP_GET_BASE_REPORT = 'OcppGetBaseReport',
  OCPP_CHANGE_CONFIGURATION = 'OcppChangeConfiguration',
  OCPP_REMOTE_START_TRANSACTION = 'OcppRemoteStartTransaction',
  OCPP_REMOTE_STOP_TRANSACTION = 'OcppRemoteStopTransaction',
  OCPP_REQUEST_START_TRANSACTION = 'OcppRequestStartTransaction',
  OCPP_REQUEST_STOP_TRANSACTION = 'OcppRequestStopTransaction',
  OCPP_UNLOCK_CONNECTOR = 'OcppUnlockConnector',
  OCPP_SET_CHARGING_PROFILE = 'OcppSetChargingProfile',
  OCPP_GET_COMPOSITE_SCHEDULE = 'OcppGetCompositeSchedule',
  OCPP_CLEAR_CHARGING_PROFILE = 'OcppClearChargingProfile',
  OCPP_GET_DIAGNOSTICS = 'OcppGetDiagnostics',
  OCPP_UPDATE_FIRMWARE = 'OcppUpdateFirmware',
  OCPP_CHANGE_AVAILABILITY = 'OcppChangeAvailability',
  OCPP_BOOT_NOTIFICATION = 'OcppBootNotification',
  OCPP_AUTHORIZE = 'OcppAuthorize',
  OCPP_HEARTBEAT = 'OcppHeartbeat',
  OCPP_DIAGNOSTICS_STATUS_NOTIFICATION = 'OcppDiagnosticsStatusNotification',
  OCPP_LOG_STATUS_NOTIFICATION = 'OcppLogStatusNotification',
  OCPP_FIRMWARE_STATUS_NOTIFICATION = 'OcppFirmwareStatusNotification',
  OCPP_STATUS_NOTIFICATION = 'OcppStatusNotification',
  OCPP_NOTIFY_REPORT = 'OcppNotifyReport',
  OCPP_START_TRANSACTION = 'OcppStartTransaction',
  OCPP_STOP_TRANSACTION = 'OcppStopTransaction',
  OCPP_TRANSACTION_EVENT = 'OcppTransactionEvent',
  OCPP_METER_VALUES = 'OcppMeterValues',
  OCPP_DATA_TRANSFER = 'OcppDataTransfer',
  OCPP_TRIGGER_MESSAGE = 'OcppTriggerMessage',
  OCPP_SIGN_CERTIFICATE = 'OcppSignCertificate',
  OCPP_CERTIFICATE_SIGNED = 'OcppCertificateSigned',
  OCPP_GET_INSTALLED_CERTIFICATE_IDS = 'OcppGetInstalledCertificateIds',
  OCPP_GET_CERTIFICATE_STATUS = 'OcppGetCertificateStatus',
  OCPP_DELETE_CERTIFICATE = 'OcppDeleteCertificate',
  OCPP_INSTALL_CERTIFICATE = 'OcppInstallCertificate',
  OCPP_GET_15118_EV_CERTIFICATE = 'OcppGet15118EVCertificate',
  OCPP_SET_NETWORK_PROFILE = 'OcppSetNetworkProfile',
  OCPP_SECURITY_EVENT_NOTIFICATION = 'SecurityEventNotification',

  CHARGING_STATION_REQUEST_OCPP_PARAMETERS = 'ChargingStationRequestOcppParameters',
  CHARGING_STATION_CLIENT_INITIALIZATION = 'ChargingStationClientInitialization',
  CHARGING_STATION_DOWNLOAD_QR_CODE = 'ChargingStationDownloadQrCode',
  CHARGING_STATION_GENERATE_QR_CODE = 'ChargingStationGenerateQrCode',
  CHARGING_STATIONS_EXPORT = 'ChargingStationsExport',
  CHARGING_STATIONS_OCPP_PARAMS_EXPORT = 'ChargingStationsOcppParamsExport',
  CHARGING_STATION = 'ChargingStation',
  CHARGING_STATIONS_OCPP_PARAMETERS = 'ChargingStationOcppParameters',
  CHARGING_STATIONS_IN_ERROR = 'ChargingStationsInError',
  CHARGING_STATION_UPDATE_PARAMS = 'ChargingStationUpdateParams',
  CHARGING_STATION_LIMIT_POWER = 'ChargingStationLimitPower',
  CHARGING_STATION_DELETE = 'ChargingStationDelete',
  CHARGING_STATION_RESERVE_NOW = 'ChargingStationReserveNow',
  CHARGING_STATION_CANCEL_RESERVATION = 'ChargingStationCancelReservation',
  CHARGING_STATION_CERTIFICATES = 'ChargingStationCertificates',

  CHECK_SMART_CHARGING_CONNECTION = 'CheckSmartChargingConnection',
  TRIGGER_SMART_CHARGING = 'TriggerSmartCharging',

  REGISTRATION_TOKEN = 'RegistrationToken',
  REGISTRATION_TOKENS = 'RegistrationTokens',
  REGISTRATION_TOKEN_DELETE = 'RegistrationTokenDelete',
  REGISTRATION_TOKEN_REVOKE = 'RegistrationTokenRevoke',
  REGISTRATION_TOKEN_UPDATE = 'RegistrationTokenUpdate',

  CHARGING_STATION_TEMPLATE = 'ChargingStationTemplate',
  CHARGING_STATION_TEMPLATES = 'ChargingStationTemplates',
  CHARGING_STATION_TEMPLATE_DELETE = 'ChargingStationTemplateDelete',
  CHARGING_STATION_TEMPLATE_UPDATE = 'ChargingStationTemplateUpdate',
  CHARGING_STATION_TEMPLATE_CREATE = 'ChargingStationTemplateCreate',

  BOOT_NOTIFICATIONS = 'BootNotifications',
  STATUS_NOTIFICATIONS = 'StatusNotifications',

  TRANSACTION_START = 'TransactionStart',
  TRANSACTION_STOP = 'TransactionStop',
  TRANSACTION_SOFT_STOP = 'TransactionSoftStop',
  TRANSACTION_DELETE = 'TransactionDelete',
  TRANSACTION_UPDATE = 'TransactionUpdate',
  TRANSACTIONS_DELETE = 'TransactionsDelete',

  LOGS = 'Logs',
  LOG = 'Log',
  LOGS_EXPORT = 'LogsExport',

  CHARGING_STATIONS = 'ChargingStations',

  CAR_CATALOGS = 'CarCatalogs',
  CAR_CATALOG = 'CarCatalog',
  CAR_CATALOG_IMAGE = 'CarCatalogImage',
  CAR_CATALOG_IMAGES = 'CarCatalogImages',
  CAR_MAKERS = 'CarMakers',
  CAR_CREATE = 'CarCreate',
  CAR_UPDATE = 'CarUpdate',
  CAR_DELETE = 'CarDelete',
  CARS = 'Cars',
  CAR = 'Car',
  SYNCHRONIZE_CAR_CATALOGS = 'SynchronizeCarCatalogs',

  GET_CONNECTOR_CURRENT_LIMIT = 'GetConnectorCurrentLimit',
  GUEST_CHECK_CHARGING_STATION = 'GuestCheckChargingStation',
  VERIFY_GUEST_USER_EMAIL = 'VerifyGuestUserEmail',
  CREATE_GUEST_USER_PAYMENT = 'GuestUserCreatePayment',
  CONFIRM_GUEST_USER_PAYMENT = 'GuestUserConfirmPayment',

  CREATE_SUBSCRIPTION = 'CreateSubscription',
  GET_SUBSCRIPTIONS = 'GetSubscriptions',
  GET_SUBSCRIPTION = 'GetSubscription',
  UPDATE_SUBSCRIPTION = 'UpdateSubscription',
  DELETE_SUBSCRIPTION = 'DeleteSubscription',

  CREATE_USER_SUBSCRIPTION = 'CreateUserSubscription',
  GET_USER_SUBSCRIPTIONS = 'GetUserSubscriptions',
  CANCEL_USER_SUBSCRIPTION = 'CancelUserSubscription',

  CHARGING_PROFILES = 'ChargingProfiles',
  CHARGING_PROFILE_DELETE = 'ChargingProfileDelete',
  CHARGING_PROFILE_UPDATE = 'ChargingProfileUpdate',
  CHARGING_PROFILE_CREATE = 'ChargingProfileCreate',
  OCPP_PARAM_UPDATE = 'OcppParamUpdate',
  RESEND_VERIFICATION_MAIL = 'ResendVerificationEmail',
  END_USER_LICENSE_AGREEMENT = 'EndUserLicenseAgreement',
  CHECK_END_USER_LICENSE_AGREEMENT = 'CheckEndUserLicenseAgreement',
  CHECK_PRIVACY_POLICY = 'CheckPrivacyPolicy',
  VERIFY_EMAIL = 'VerifyEmail',
  FIRMWARE_DOWNLOAD = 'FirmwareDownload',

  SCHEMA_VALIDATION = 'SchemaValidation',
  SCHEDULER = 'Scheduler',
  BLACKLIST = 'Blacklist',
  DELETE_BLACKLIST = 'DeleteBlacklist',
  ASYNC_TASK = 'AsyncTask',

  SYNCHRONIZE_REFUND_TRANSACTIONS = 'SynchronizeRefundTransactions',

  REGISTRATION_TOKEN_CREATE = 'RegistrationTokenCreate',

  INTEGRATION_CONNECTION_CREATE = 'IntegrationConnectionCreate',
  INTEGRATION_CONNECTIONS = 'IntegrationConnections',
  INTEGRATION_CONNECTION = 'IntegrationConnection',
  INTEGRATION_CONNECTION_DELETE = 'IntegrationConnectionDelete',

  OCPI_SETTINGS = 'OcpiSettings',
  OCPI_CLIENT_INITIALIZATION = 'OcpiClientInitialization',
  OCPI_ENDPOINT_CREATE = 'OcpiEndpointCreate',
  OCPI_ENDPOINT_PING = 'OcpiEndpointPing',
  OCPI_ENDPOINT_CHECK_CDRS = 'OcpiEndpointCheckCdrs',
  OCPI_ENDPOINT_CHECK_LOCATIONS = 'OcpiEndpointCheckLocations',
  OCPI_ENDPOINT_CHECK_SESSIONS = 'OcpiEndpointCheckSessions',
  OCPI_ENDPOINT_SEND_EVSE_STATUSES = 'OcpiEndpointSendEVSEStatuses',
  OCPI_ENDPOINT_SEND_TOKENS = 'OcpiEndpointSendTokens',
  OCPI_ENDPOINT_GENERATE_LOCAL_TOKEN = 'OcpiEndpointGenerateLocalToken',
  OCPI_ENDPOINT_UPDATE = 'OcpiEndpointUpdate',
  OCPI_ENDPOINT_REGISTER = 'OcpiEndpointRegister',
  OCPI_ENDPOINT_UNREGISTER = 'OcpiEndpointUnregister',
  OCPI_ENDPOINT_CREDENTIALS = 'OcpiEndpointCredentials',
  OCPI_ENDPOINT_DELETE = 'OcpiEndpointDelete',
  OCPI_ENDPOINTS = 'OcpiEndpoints',
  OCPI_ENDPOINT = 'OcpiEndpoint',
  OCPI_REGISTER = 'OcpiRegister',
  OCPI_UNREGISTER = 'OcpiUnregister',
  OCPI_GET_VERSIONS = 'OcpiGetVersions',
  OCPI_GET_ENDPOINT_VERSIONS = 'OcpiGetEndpointVersions',
  OCPI_CREATE_CREDENTIALS = 'OcpiCreateCredentials',
  OCPI_UPDATE_CREDENTIALS = 'OcpiUpdateCredentials',
  OCPI_DELETE_CREDENTIALS = 'OcpiDeleteCredentials',

  OCPI_CPO_REQUEST = 'OcpiCpoRequest',
  OCPI_CPO_GET_SERVICES = 'OcpiCpoGetServices',
  OCPI_CPO_COMMAND = 'OcpiCpoCommand',
  OCPI_CPO_PUSH_STATUS = 'OcpiCpoPushStatus',
  OCPI_CPO_CHECK_CDRS = 'OcpiCpoCheckCdrs',
  OCPI_CPO_CHECK_SESSIONS = 'OcpiCpoCheckSessions',
  OCPI_CPO_GET_SESSIONS = 'OcpiCpoGetSessions',
  OCPI_CPO_PUSH_SESSIONS = 'OcpiCpoPushSessions',
  OCPI_CPO_START_SESSION = 'OcpiCpoStartSession',
  OCPI_CPO_STOP_SESSION = 'OcpiCpoStopSession',
  OCPI_CPO_CHECK_LOCATIONS = 'OcpiCpoCheckLocations',
  OCPI_CPO_GET_LOCATIONS = 'OcpiCpoGetLocations',
  OCPI_CPO_GET_CDRS = 'OcpiCpoGetCdrs',
  OCPI_CPO_PUSH_CDRS = 'OcpiCpoPushCdrs',
  OCPI_CPO_PULL_TOKENS = 'OcpiCpoPullTokens',
  OCPI_CPO_PULL_TOKEN = 'OcpiCpoPullToken',
  OCPI_CPO_PUSH_TOKEN = 'OcpiCpoPushToken',
  OCPI_CPO_AUTHORIZE_TOKEN = 'OcpiCpoAuthorizeToken',
  OCPI_CPO_GET_TARIFFS = 'OcpiCpoGetTariffs',

  OCPI_EMSP_REQUEST = 'OcpiEmspRequest',
  OCPI_EMSP_GET_SERVICES = 'OcpiEmspGetServices',
  OCPI_EMSP_COMMAND = 'OcpiEmspCommand',
  OCPI_EMSP_AUTHORIZE_TOKEN = 'OcpiEmspAuthorizeToken',
  OCPI_EMSP_PUSH_LOCATION = 'OcpiEmspPushLocation',
  OCPI_EMSP_PULL_LOCATIONS = 'OcpiEmspPullLocations',
  OCPI_EMSP_PULL_SESSIONS = 'OcpiEmspPullSessions',
  OCPI_EMSP_PUSH_SESSION = 'OcpiEmspPushSession',
  OCPI_EMSP_START_SESSION = 'OcpiEmspStartSession',
  OCPI_EMSP_STOP_SESSION = 'OcpiEmspStopSession',
  OCPI_EMSP_PUSH_TOKENS = 'OcpiEmspPushTokens',
  OCPI_EMSP_PULL_CDR = 'OcpiEmspPullCdr',
  OCPI_EMSP_PULL_CDRS = 'OcpiEmspPullCdrs',
  OCPI_EMSP_GET_TOKENS = 'OcpiEmspGetTokens',
  OCPI_EMSP_CREATE_CDR = 'OcpiEmspCreateCdr',
  OCPI_EMSP_RESERVE_NOW = 'OcpiEmspReserveNow',
  OCPI_EMSP_UNLOCK_CONNECTOR = 'OcpiEmspUnlockConnector',
  OCPI_EMSP_GET_TARIFF = 'OcpiEmspGetTariff',

  OICP_CPO_REQUEST = 'OicpCpoRequest',
  OICP_CPO_GET_SERVICES = 'OicpCpoGetServices',
  OICP_SETTINGS = 'OicpSettings',
  OICP_ENDPOINT_CREATE = 'OicpEndpointCreate',
  OICP_ENDPOINT_PING = 'OicpEndpointPing',
  OICP_ENDPOINT = 'OicpEndpoint',
  OICP_ENDPOINTS = 'OicpEndpoints',
  OICP_PUSH_EVSES = 'OicpPushEvses',
  OICP_PUSH_EVSE_STATUSES = 'OicpPushEvseStatuses',
  OICP_UPDATE_EVSE_STATUS = 'OicpUpdateEvseStatus',
  OICP_AUTHORIZE_START = 'OicpAuthorizeStart',
  OICP_AUTHORIZE_STOP = 'OicpAuthorizeStop',
  OICP_AUTHORIZE_REMOTE_START = 'OicpAuthorizeRemoteStart',
  OICP_AUTHORIZE_REMOTE_STOP = 'OicpAuthorizeRemoteStop',
  OICP_PUSH_CDR = 'OicpPushCdr',
  OICP_SEND_CHARGING_NOTIFICATION_START = 'OicpSendChargingNotificationStart',
  OICP_SEND_CHARGING_NOTIFICATION_PROGRESS = 'OicpSendChargingNotificationProgress',
  OICP_SEND_CHARGING_NOTIFICATION_END = 'OicpSendChargingNotificationEnd',
  OICP_SEND_CHARGING_NOTIFICATION_ERROR = 'OicpSendChargingNotificationError',
  OICP_ENDPOINT_SEND_EVSE_STATUSES = 'OicpEndpointSendEVSEStatuses',
  OICP_ENDPOINT_SEND_EVSES = 'OicpEndpointSendEVSEs',
  OICP_PUSH_SESSIONS = 'OicpPushSessions',
  OICP_CREATE_AXIOS_INSTANCE = 'OicpCreateAxiosInstance',
  OICP_ENDPOINT_UPDATE = 'OicpEndpointUpdate',
  OICP_ENDPOINT_DELETE = 'OicpEndpointDelete',
  OICP_GET_VERSIONS = 'OicpGetVersions',

  OCPP_SERVICE = 'OcppService',

  AUTHORIZATIONS = 'Authorizations',

  MONITORING = 'Monitoring',

  EXPRESS_SERVER = 'ExpressServer',
  ODATA_SERVER = 'ODataServer',

  LOCKING = 'Locking',

  STARTUP = 'Startup',
  DB_CACHE = 'DatabaseCache',

  BOOTSTRAP_STARTUP = 'BootstrapStartup',

  EXTRA_INACTIVITY = 'ExtraInactivity',

  CONSUMPTION = 'Consumption',

  WS_SERVER_REDIRECT_CONNECTION = 'WsServerRedirectConnection',
  WS_SERVER_REDIRECT_CONNECTION_OPEN = 'WsServerRedirectConnectionOpen',
  WS_SERVER_REDIRECT_CONNECTION_CLOSE = 'WsServerRedirectConnectionClose',
  WS_SERVER_REDIRECT_CONNECTION_ERROR = 'WsServerRedirectConnectionError',
  WS_SERVER_REDIRECT_MESSAGE = 'WsServerRedirectMessage',
  WS_SERVER_REDIRECT_CONNECTION_PING = 'WsServerRedirectConnectionPing',

  WS_SERVER_MESSAGE = 'WsServerMessage',
  WS_SERVER_CONNECTION = 'WsServerConnection',
  WS_SERVER_CONNECTION_PING = 'WsServerConnectionPing',
  WS_SERVER_CONNECTION_LAST_SEEN = 'WsServerConnectionLastSeen',
  WS_SERVER_CONNECTION_CLOSE = 'WsServerConnectionClose',
  WS_SERVER_CONNECTION_DRAIN = 'WsServerConnectionDrain',
  WS_SERVER_CONNECTION_OPEN = 'WsServerConnectionOpen',
  WS_SERVER_CONNECTION_REFRESH = 'WsServerConnectionRefresh',
  WS_SERVER_CONNECTION_ERROR = 'WsServerConnectionError',
  WS_SERVER_CONNECTION_QUEUE = 'WsServerConnectionQueue',

  WS_SERVER_REST_MESSAGE = 'WsServerRestMessage',
  WS_SERVER_REST_CONNECTION = 'WsServerRestConnection',
  WS_SERVER_REST_CONNECTION_PING = 'WsServerRestConnectionPing',
  WS_SERVER_REST_CONNECTION_CLOSE = 'WsServerRestConnectionClose',
  WS_SERVER_REST_CONNECTION_DRAIN = 'WsServerRestConnectionDrain',
  WS_SERVER_REST_CONNECTION_OPEN = 'WsServerRestConnectionOpen',
  WS_SERVER_REST_CONNECTION_ERROR = 'WsServerRestConnectionError',
  WS_SERVER_REST_CONNECTION_QUEUE = 'WsServerRestConnectionQueue',

  WS_CLIENT_ERROR = 'WsClientError',
  WS_CLIENT_MESSAGE = 'WsClientMessage',
  WS_CLIENT_CONNECTION_PING = 'WsClientConnectionPing',
  WS_CLIENT_CONNECTION_OPEN = 'WsClientConnectionOpen',
  WS_CLIENT_CONNECTION_CLOSE = 'WsClientConnectionClose',
  WS_CLIENT_CONNECTION_DRAIN = 'WsClientConnectionDrain',

  WS_CLIENT_REST_ERROR = 'WsClientRestError',
  WS_CLIENT_REST_MESSAGE = 'WsClientRestMessage',
  WS_CLIENT_REST_CONNECTION = 'WsClientRestConnection',
  WS_CLIENT_REST_CONNECTION_PING = 'WsClientRestConnectionPing',
  WS_CLIENT_REST_CONNECTION_OPEN = 'WsClientRestConnectionOpen',
  WS_CLIENT_REST_CONNECTION_CLOSE = 'WsClientRestConnectionClose',
  WS_CLIENT_REST_CONNECTION_ERROR = 'WsClientRestConnectionError',
  WS_CLIENT_REST_CONNECTION_DRAIN = 'WsClientRestConnectionDrain',

  NOTIFICATION = 'Notification',
  REMOTE_PUSH_NOTIFICATION = 'RemotePushNotification',
  EMAIL_NOTIFICATION = 'EmailNotification',

  UPDATE_LOCAL_CAR_CATALOGS = 'UpdateLocalCarCatalogs',
  CAR_CATALOG_SYNCHRONIZATION = 'CarCatalogSynchronization',

  EULA_INITIALIZATION = 'EulaInitialization',

  UPDATE_CHARGING_STATION_WITH_TEMPLATE = 'UpdateChargingStationWithTemplate',

  MIGRATION = 'Migration',

  CLEANUP_TRANSACTION = 'CleanupTransaction',
  TRANSACTIONS_COMPLETED = 'TransactionsCompleted',
  TRANSACTIONS_TO_REFUND = 'TransactionsToRefund',
  TRANSACTIONS_TO_REFUND_EXPORT = 'TransactionsToRefundExport',
  TRANSACTIONS_TO_REFUND_REPORTS = 'TransactionsRefundReports',
  TRANSACTIONS_EXPORT = 'TransactionsExport',
  TRANSACTIONS_ACTIVE = 'TransactionsActive',
  TRANSACTIONS_IN_ERROR = 'TransactionsInError',
  TRANSACTION_YEARS = 'TransactionYears',
  TRANSACTION = 'Transaction',
  TRANSACTIONS = 'Transactions',
  TRANSACTION_CONSUMPTION = 'TransactionConsumption',
  TRANSACTION_EXPORT_CDR = 'TransactionExportCdr',
  TRANSACTION_PUSH_CDR = 'TransactionPushCdr',

  CHARGING_STATION_CONSUMPTION_STATISTICS = 'ChargingStationConsumptionStatistics',
  CHARGING_STATION_USAGE_STATISTICS = 'ChargingStationUsageStatistics',
  CHARGING_STATION_INACTIVITY_STATISTICS = 'ChargingStationInactivityStatistics',
  CHARGING_STATION_TRANSACTIONS_STATISTICS = 'ChargingStationTransactionsStatistics',
  CHARGING_STATION_PRICING_STATISTICS = 'ChargingStationPricingStatistics',
  STATISTICS_EXPORT = 'StatisticsExport',
  USER_CONSUMPTION_STATISTICS = 'UserConsumptionStatistics',
  USER_USAGE_STATISTICS = 'UserUsageStatistics',
  USER_INACTIVITY_STATISTICS = 'UserInactivityStatistics',
  USER_TRANSACTIONS_STATISTICS = 'UserTransactionsStatistics',
  USER_PRICING_STATISTICS = 'UserPricingStatistics',

  CHARGING_STATION_TRANSACTIONS = 'ChargingStationTransactions',

  ASSET_CREATE = 'AssetCreate',
  ASSETS = 'Assets',
  ASSET = 'Asset',
  ASSET_IMAGE = 'AssetImage',
  ASSETS_IN_ERROR = 'AssetsInError',
  ASSET_UPDATE = 'AssetUpdate',
  ASSET_DELETE = 'AssetDelete',
  CHECK_ASSET_CONNECTION = 'CheckAssetConnection',
  ASSET_GET_CONSUMPTION = 'AssetGetConsumption',
  ASSET_CONSUMPTION = 'AssetConsumption',

  TENANT_CREATE = 'TenantCreate',
  TENANTS = 'Tenants',
  TENANT = 'Tenant',
  TENANT_UPDATE = 'TenantUpdate',
  TENANT_UPDATE_DATA = 'TenantUpdateData',
  TENANT_DELETE = 'TenantDelete',
  TENANT_LOGO = 'TenantLogo',
  TENANT_GENERATE_QR_CODE = 'TenantGenerateQrCode',
  TENANT_DOWNLOAD_QR_CODE = 'TenantDownloadQrCode',
  TENANT_PUBLIC_INFO = 'TenantPublicInfo',

  COMPANY_CREATE = 'CompanyCreate',
  COMPANIES = 'Companies',
  COMPANY = 'Company',
  COMPANY_LOGO = 'CompanyLogo',
  COMPANY_UPDATE = 'CompanyUpdate',
  COMPANY_DELETE = 'CompanyDelete',

  SITE_CREATE = 'SiteCreate',
  SITES = 'Sites',
  SITE = 'Site',
  SITE_IMAGE = 'SiteImage',
  SITE_USERS = 'SiteUsers',
  SITE_UPDATE = 'SiteUpdate',
  SITE_USER_ADMIN = 'SiteUserAdmin',
  SITE_OWNER = 'SiteOwner',
  SITE_DELETE = 'SiteDelete',
  SITE_ASSIGN_USERS = 'SiteAssignUsers',
  SITE_UNASSIGN_USERS = 'SiteUnassignUsers',

  SITE_AREA_CREATE = 'SiteAreaCreate',
  SITE_AREAS = 'SiteAreas',
  SITE_AREA = 'SiteArea',
  SITE_AREA_IMAGE = 'SiteAreaImage',
  SITE_AREA_CONSUMPTION = 'SiteAreaConsumption',
  SITE_AREA_UPDATE = 'SiteAreaUpdate',
  SITE_AREA_DELETE = 'SiteAreaDelete',
  SITE_AREA_ASSIGN_ASSETS = 'SiteAreaAssignAssets',
  SITE_AREA_UNASSIGN_ASSETS = 'SiteAreaUnassignAssets',
  SITE_AREA_ASSIGN_CHARGING_STATIONS = 'SiteAreaAssignChargingStations',
  SITE_AREA_UNASSIGN_CHARGING_STATIONS = 'SiteAreaUnassignChargingStations',

  TRANSACTIONS_REFUND = 'TransactionsRefund',
  SYNCHRONIZE_REFUNDED_TRANSACTIONS = 'SynchronizeRefundedTransactions',

  SETTING_CREATE = 'SettingCreate',
  SETTING_BY_IDENTIFIER = 'SettingByIdentifier',
  SETTINGS = 'Settings',
  SETTING = 'Setting',
  SETTING_UPDATE = 'SettingUpdate',
  SETTING_DELETE = 'SettingDelete',

  REFUND = 'Refund',
  CAR_CONNECTOR = 'CarConnector',

  USER_CREATE = 'UserCreate',
  USER_DELETE = 'UserDelete',
  USER_UPDATE = 'UserUpdate',
  USER_UPDATE_MOBILE_DATA = 'UpdateUserMobileData',
  USERS = 'Users',
  USER_SITES = 'UserSites',
  USERS_IN_ERROR = 'UsersInError',
  USER_IMAGE = 'UserImage',
  USER_ASSIGN_SITES = 'UserAssignSites',
  USER_UNASSIGN_SITES = 'UserUnassignSites',

  PLANNINGS = 'Plannings',
  PLANNING = 'Planning',
  PLANNING_CREATE = 'PlanningCreate',
  PLANNING_UPDATE = 'PlanningUpdate',
  PLANNING_DELETE = 'PlanningDelete',

  USER_GROUPS = 'UserGroups',
  USER_GROUP = 'UserGroup',
  USER_GROUP_CREATE = 'UserGroupCreate',
  USER_GROUP_UPDATE = 'UserGroupUpdate',
  USER_GROUP_DELETE = 'UserGroupDelete',
  USER_GROUP_ASSIGN_USERS = 'UserGroupAssignUsers',
  USER_GROUP_UNASSIGN_USERS = 'UserGroupUnassignUsers',

  TAGS = 'Tags',
  TAG = 'Tag',
  TAG_BY_VISUAL_ID = 'TagByVisualID',
  USER_TRANSACTION_PREREQUISITES = 'UserTransactionPrerequisites',
  USER_SUBSCRIPTION_PREREQUISITES = 'UserSubscriptionPrerequisites',
  TAG_CREATE = 'TagCreate',
  TAG_UPDATE = 'TagUpdate',
  TAG_DELETE = 'TagDelete',
  TAGS_UNASSIGN = 'TagsUnassign',
  TAG_UNASSIGN = 'TagUnassign',
  TAG_ASSIGN = 'TagAssign',
  TAGS_DELETE = 'TagsDelete',
  TAGS_IMPORT = 'TagsImport',
  TAGS_EXPORT = 'TagsExport',
  USER = 'User',
  USERS_EXPORT = 'UsersExport',
  USERS_IMPORT = 'UsersImport',

  NOTIFICATIONS = 'Notifications',
  TRANSACTION_STARTED = 'SessionStarted',
  OPTIMAL_CHARGE_REACHED = 'OptimalChargeReached',
  END_OF_CHARGE = 'EndOfCharge',
  TRANSACTION_STOPPED = 'EndOfSession',
  GUEST_TRANSACTION_STOPPED = 'GuestTransactionStopped',
  USER_ACCOUNT_STATUS_CHANGED = 'UserAccountStatusChanged',
  UNKNOWN_USER_BADGED = 'UnknownUserBadged',
  CHARGING_STATION_STATUS_ERROR = 'ChargingStationStatusError',
  CHARGING_STATION_REGISTERED = 'ChargingStationRegistered',
  OCPI_PATCH_STATUS_ERROR = 'OcpiPatchStatusError',
  OICP_PATCH_STATUS_ERROR = 'OicpPatchStatusError',
  OICP_PATCH_EVSE_ERROR = 'OicpPatchEvseError',
  OICP_PUSH_CDRS_TASK = 'OicpPushCdrsTask',
  USER_ACCOUNT_CREATED = 'UserAccountCreated',
  GUEST_USER_ACCOUNT_CREATED = 'GuestUserAccountCreated',
  USER_REQUEST_PASSWORD = 'UserRequestPassword',
  BILLING_PREPAID_PAYMENT_FAILED = 'BillingPrepaidPaymentFailed',
  END_USER_REPORT_ERROR = 'EndUserReportError',
  BILLING_NEW_INVOICE = 'BillingNewInvoice',
  BILLING_ACCOUNT_CREATED = 'BillingAccountCreated',
  BILLING_ACCOUNT_ACTIVATED = 'BillingAccountActivated',
  CHECK_AND_APPLY_SMART_CHARGING_FAILED = 'ComputeAndApplyChargingProfilesFailed',
  USER_ACCOUNT_VERIFIED = 'UserAccountVerified',
  USER_ACCOUNT_VERIFICATION = 'UserAccountVerification',
  USER_IMPORTED_ACCOUNT_VERIFICATION = 'UserImportedAccountVerification',
  DELETE_OCPI_ONGOING_AND_INACTIVE_TRANSACTIONS_TASK = 'DeleteOcpiOngoingTransactionsTask',
  SEND_CHANGE_COMPANY_CAR_ODOMETER_NOTIFICATION = 'SendChangeCompanyCarOdometerNotification',
  SEND_CREATE_CAR = 'SendCreateCarNotification',

  OCPI_CPO_CHECK_CDRS_ASYNC_TASK = 'OcpiCpoCheckCdrsAsyncTask',
  OCPI_CPO_CHECK_LOCATIONS_ASYNC_TASK = 'OcpiCpoCheckLocationsAsyncTask',
  OCPI_CPO_CHECK_SESSIONS_ASYNC_TASK = 'OcpiCpoCheckSessionsAsyncTask',
  OCPI_CPO_PULL_TOKENS_ASYNC_TASK = 'OcpiCpoPullTokensAsyncTask',
  OCPI_CPO_PUSH_EVSE_STATUSES_ASYNC_TASK = 'OcpiCpoPushEVSEStatusesAsyncTask',
  OCPI_EMSP_PULL_CDRS_ASYNC_TASK = 'OcpiEmspPullCdrsAsyncTask',
  OCPI_EMSP_PULL_LOCATIONS_ASYNC_TASK = 'OcpiEmspPullLocationsAsyncTask',
  OCPI_EMSP_PULL_SESSIONS_ASYNC_TASK = 'OcpiEmspPullSessionsAsyncTask',
  OCPI_EMSP_PUSH_TOKENS_ASYNC_TASK = 'OcpiEmspPushTokensAsyncTask',
  OICP_CPO_PUSH_EVSE_STATUSES_ASYNC_TASK = 'OicpCpoPushEVSEStatusesAsyncTask',
  OICP_CPO_PUSH_EVSES_ASYNC_TASK = 'OicpCpoPushEVSEsAsyncTask',
  APPLY_CHARGING_STATION_TEMPLATE_ASYNC_TASK = 'ApplyChargingStationTemplateAsyncTask',
  APPLY_OCPP_TEMPLATE_PARAMETERS_ASYNC_TASK = 'ApplyOcppTemplateParametersAsyncTask',
  BILL_TRANSACTION_ASYNC_TASK = 'BillTransactionAsyncTask',
  BILL_USER_SUBSCRIPTION_ASYNC_TASK = 'BillUserSubscriptionAsyncTask',
  UPDATE_ENTITIES_WITH_ORGANIZATION_IDS_ASYNC_TASK = 'UpdateEntitiesWithOrganizationIDsAsyncTask',
  CHECK_LAST_TRANSACTION_OCPP_STATUS_NOTIFICATION_ASYNC_TASK = 'CheckLastTransactionOcppStatusNotificationAsyncTask',
  OCPP_GET_CONFIGURATION_ASYNC_TASK = 'OcppGetConfigurationAsyncTask',
  REMOTE_STOP_TRANSACTION_ASYNC_TASK = 'RemoteStopTransactionAsyncTask',
  SEND_NOTIFICATION_ASYNC_TASK = 'SendNotificationAsyncTask',
  SIGN_CHARGING_STATION_CERTIFICATE_ASYNC_TASK = 'SignChargingStationCertificateAsyncTask',
  SMART_CHARGING_ASYNC_TASK = 'SmartChargingAsyncTask',
  SYNCHRONIZE_CAR_CATALOGS_ASYNC_TASK = 'SynchronizeCarCatalogsAsyncTask',
  TAGS_IMPORT_ASYNC_TASK = 'TagsImportAsyncTask',
  UPDATE_ROAMING_OCPP_STATUS_NOTIFICATION_ASYNC_TASK = 'UpdateRoamingOcppStatusNotificationAsyncTask',
  USERS_IMPORT_ASYNC_TASK = 'UsersImportAsyncTask',

  ADD_COMPANY_ID_TO_SITE_AREAS_MIGRATION_TASK = 'AddCompanyIDToSiteAreasMigrationTask',
  ADD_INVOICE_AMOUNT_WITH_DECIMAL_MIGRATION_TASK = 'AddInvoiceAmountWithDecimalMigrationTask',
  ADD_INVOICE_TYPE_MIGRATION_TASK = 'AddInvoiceTypeMigrationTask',
  ADD_OFFLINE_FLAG_TO_TRANSACTIONS_MIGRATION_TASK = 'AddOfflineFlagToTransactionsMigrationTask',
  ADD_COMPANY_ID_TO_CONSUMPTION_MIGRATION_TASK = 'AddCompanyIDToConsumptionMigrationTask',
  ADD_CAR_SETTINGS_MIGRATION_TASK = 'AddCarSettingsMigrationTask',
  ADD_COMPANY_ID_AND_SITE_ID_TO_REGISTRATION_TOKEN_MIGRATION_TASK = 'AddCompanyIdAndSiteIdToRegistrationTokenMigrationTask',
  ADD_ROAMING_FLAGS_TO_TRANSACTIONS_MIGRATION_TASK = 'AddRoamingFlagsToTransactionsMigrationTask',
  ADD_FINALIZED_FLAG_TO_TRANSACTIONS_MIGRATION_TASK = 'AddFinalizedFlagToTransactionsMigrationTask',
  ASSIGN_ALL_ADMIN_USERS_TO_SITE_USERS_MIGRATION_TASK = 'AssignAllAdminUsersToSiteUsersMigrationTask',
  DELETE_LOGICALLY_DELETED_CHARGING_STATIONS_MIGRATION_TASK = 'DeleteLogicallyDeletedChargingStationsMigrationTask',
  DELETE_PRICING_DEFINITION_WITH_MISSING_ENTITY_MIGRATION_TASK = 'DeletePricingDefinitionWithMissingEntityMigrationTask',
  DELETE_UNKNOWN_USERS_IN_SITE_USERS_MIGRATION_TASK = 'DeleteUnknownUsersInSiteUsersMigrationTask',
  REMOVE_TRANSACTION_RUNTIME_DATA_TENANT_MIGRATION_TASK = 'RemoveTransactionRuntimeDataTenantMigrationTask',
  SET_AUTO_ASSIGN_USER_FOR_ROAMING_SITES_MIGRATION_TASK = 'SetAutoAssignUserForRoamingSitesMigrationTask',
  UPDATE_OCPI_SITES_MIGRATION_TASK = 'UpdateOcpiSitesMigrationTask',

  USER_ACCOUNT_INACTIVITY_SCHEDULER_TASK = 'UserAccountInactivitySchedulerTask',
  CHECK_OFFLINE_CHARGING_STATIONS_SCHEDULER_TASK = 'CheckOfflineChargingStationsSchedulerTask',
  OICP_CPO_PUSH_EVSE_SCHEDULER_TASK = 'OicpCpoPushEVSESchedulerTask',
  OICP_CPO_PUSH_EVSE_STATUSES_SCHEDULER_TASK = 'OicpCpoPushEVSEStatusesSchedulerTask',
  OICP_CPO_CHECK_CDRS_SCHEDULER_TASK = 'OicpCpoCheckCdrsSchedulerTask',
  OCPI_CPO_PUSH_EVSE_STATUSES_SCHEDULER_TASK = 'OcpiCpoPushEVSEStatusesSchedulerTask',
  OCPI_EMSP_PULL_CDRS_SCHEDULER_TASK = 'OcpiEmspPullCdrsSchedulerTask',
  OCPI_EMSP_PULL_LOCATIONS_SCHEDULER_TASK = 'OcpiEmspPullLocationsSchedulerTask',
  OCPI_EMSP_PULL_SESSIONS_SCHEDULER_TASK = 'OcpiEmspPullSessionsSchedulerTask',
  OCPI_CPO_CHECK_SESSIONS_SCHEDULER_TASK = 'OcpiCpoCheckSessionsSchedulerTask',
  OCPI_CPO_CHECK_CDRS_SCHEDULER_TASK = 'OcpiCpoCheckCdrsSchedulerTask',
  OCPI_CPO_PULL_TOKENS_SCHEDULER_TASK = 'OcpiCpoPullTokensSchedulerTask',
  OCPI_CPO_PUSH_CDRS_SCHEDULER_TASK = 'OcpiCpoPushCdrsSchedulerTask',
  OCPI_EMSP_PUSH_TOKENS_SCHEDULER_TASK = 'OcpiEmspPushTokensSchedulerTask',
  SYNCHRONIZE_REFUND_TRANSACTIONS_SCHEDULER_TASK = 'SynchronizeRefundTransactionsSchedulerTask',
  BILLING_PERIODIC_OPERATION_SCHEDULER_TASK = 'BillingPeriodicOperationSchedulerTask',
  BILLING_BILL_PENDING_TRANSACTION_SCHEDULER_TASK = 'BillingBillPendingTransactionSchedulerTask',
  BILLING_BILL_USER_SUBSCRIPTION_PENDING_INVOICE_SCHEDULER_TASK = 'BillingBillUserSubscriptionPendingInvoiceSchedulerTask',
  DELETE_CANCELLED_USER_SUBSCRIPTION_SCHEDULER_TASK = 'DeleteCancelledUserSubscriptionSchedulerTask',
  BILLING_SYNCHRONIZE_INVOICE_STATUS_SCHEDULER_TASK = 'BillingSynchronizeInvoiceStatusSchedulerTask',
  SYNCHRONIZE_CAR_CATALOGS_SCHEDULER_TASK = 'SynchronizeCarCatalogsSchedulerTask',
  CHECK_AND_COMPUTE_SMART_CHARGING_SCHEDULER_TASK = 'CheckAndComputeSmartChargingSchedulerTask',
  ASSET_GET_CONSUMPTION_SCHEDULER_TASK = 'AssetGetConsumptionSchedulerTask',
  CHECK_CHARGING_STATION_TEMPLATE_SCHEDULER_TASK = 'CheckChargingStationTemplateSchedulerTask',
  MIGRATE_SENSITIVE_DATA_SCHEDULER_TASK = 'MigrateSensitiveDataSchedulerTask',
  CLOSE_TRANSACTIONS_IN_PROGRESS_SCHEDULER_TASK = 'CloseTransactionsInProgressSchedulerTask',
  UPDATE_TENANT_RUNTIME_DATA_SCHEDULER_TASK = 'UpdateTenantRuntimeDataSchedulerTask',
  BILLING_TRANSFER_DISPATCH_COLLECTED_FUNDS_SCHEDULER_TASK = 'BillingTransferDispatchCollectedFundsSchedulerTask',
  GRID_MONITORING_UPDATE_SITE_AREAS_SCHEDULER_TASK = 'GridMonitoringUpdateSiteAreasSchedulerTask',

  BILLING = 'Billing',
  BILLING_TRANSACTION = 'BillingTransaction',
  BILLING_USER_SUBSCRIPTION = 'BillingUserSubscription',
  BILLING_SYNCHRONIZE_USER = 'BillingSynchronizeUser',
  BILLING_CREATE_USER = 'BillingCreateUser',
  BILLING_UPDATE_USER = 'BillingUpdateUser',
  BILLING_FORCE_SYNCHRONIZE_USER = 'BillingForceSynchronizeUser',
  BILLING_CHECK_CONNECTION = 'BillingCheckConnection',
  BILLING_TAXES = 'BillingTaxes',
  BILLING_INVOICES = 'BillingInvoices',
  BILLING_SYNC_INVOICES = 'BillingSyncInvoice',
  BILLING_INVOICE = 'BillingInvoice',
  BILLING_PERIODIC_OPERATION = 'BillingPeriodicOperation',
  BILLING_DOWNLOAD_INVOICE = 'BillingDownloadInvoice',
  BILLING_DOWNLOAD_TRANSFER = 'BillingDownloadTransfer',
  BILLING_CREATE_PAYMENT_METHOD = 'BillingCreatePaymentMethod',
  BILLING_ATTACH_PAYMENT_METHOD = 'BillingAttachPaymentMethod',
  BILLING_PAYMENT_METHODS = 'BillingPaymentMethods',
  BILLING_CREATE_PAYMENT = 'BillingCreatePayment',
  BILLING_READ_PAYMENT = 'BillingReadPayment',
  BILLING_CAPTURE_PAYMENT = 'BillingCapturePayment',
  BILLING_DELETE_PAYMENT_METHOD = 'BillingDeletePaymentMethod',
  BILLING_CHARGE_INVOICE = 'BillingChargeInvoice',
  BILLING_TEST_DATA_CLEANUP = 'BillingTestDataCleanup',
  BILLING_PREPAID_PAYMENT = 'BillingPrepaidPayment',
  BILLING_ACCOUNT_CREATE = 'BillingAccountCreate',
  BILLING_ACCOUNT_DELETE = 'BillingAccountDelete',
  BILLING_ACCOUNT_ACTIVATE = 'BillingAccountActivate',
  BILLING_ACCOUNT_ONBOARD = 'BillingAccountOnboard',
  BILLING_ACCOUNT = 'BillingAccount',
  BILLING_ACCOUNTS = 'BillingAccounts',
  BILLING_TRANSFERS = 'BillingTransfers',
  BILLING_TRANSFER = 'BillingTransfer',
  BILLING_TRANSFER_FINALIZE = 'BillingTransferFinalize',
  BILLING_TRANSFER_SEND = 'BillingTransferSend',
  BILLING_TRANSFER_DISPATCH_COLLECTED_FUNDS = 'BillingTransferDispatchCollectedFunds',
  BILLING_SYNCHRONIZE_INVOICE_STATUS = 'BillingSynchronizeInvoiceStatus',

  PRICING = 'Pricing',
  PRICING_DEFINITION = 'PricingDefinition',
  PRICING_DEFINITIONS = 'PricingDefinitions',
  PRICING_DEFINITION_CREATE = 'PricingDefinitionCreate',
  PRICING_DEFINITION_UPDATE = 'PricingDefinitionUpdate',
  PRICING_DEFINITION_DELETE = 'PricingDefinitionDelete',
  PRICING_MODEL_RESOLVE = 'PricingModelResolve',

  HEALTH_CHECK = 'HealthCheck',
  MONGO_DB = 'MongoDB',

  SMART_CHARGING = 'SmartCharging',
  SIGN_CHARGING_STATION_CERTIFICATE = 'SignChargingStationCertificate',

  ECOWATT_GRID_MONITORING = 'EcowattGridMonitoring',
  TRIGGER_GRID_MONITORING = 'TriggerGridMonitoring',
  CHECK_GRID_MONITORING_CONNECTION = 'CheckGridMonitoringConnection',
  GRID_MONITORING_UPDATE_SITE_AREAS = 'GridMonitoringUpdateSiteAreas',

  HUBJECT_PLUG_AND_CHARGE = 'HubjectPlugAndCharge',
  CHECK_PLUG_AND_CHARGE_CONNECTION = 'CheckPlugAndChargeConnection',

  REST_REQUEST = 'RestRequest',
  HTTP_REQUEST = 'HttpRequest',
  HTTP_RESPONSE = 'HttpResponse',
  RATE_LIMITER = 'RateLimiter',
}

// RESTful API
export enum RESTServerRoute {
  REST_SIGN_IN = 'signin',
  REST_SIGN_ON = 'signon',
  REST_SIGN_OUT = 'signout',
  REST_PASSWORD_RESET = 'password/reset',
  REST_END_USER_LICENSE_AGREEMENT = 'eula',
  REST_END_USER_LICENSE_AGREEMENT_CHECK = 'eula/check',
  REST_END_USER_LICENSE_AGREEMENT_HTML = 'eula/html',
  REST_PRIVACY_POLICY_CHECK = 'privacy/check',
  REST_MAIL_CHECK = 'mail/check',
  REST_MAIL_RESEND = 'mail/resend',

  REST_CHARGING_STATION_TEMPLATES = 'charging-station-templates',
  REST_CHARGING_STATION_TEMPLATE = 'charging-station-templates/:id',

  REST_CHARGING_STATIONS = 'charging-stations',
  REST_CHARGING_STATION = 'charging-stations/:id',

  REST_CHARGING_STATIONS_RESET = 'charging-stations/:id/reset',
  REST_CHARGING_STATIONS_CACHE_CLEAR = 'charging-stations/:id/cache/clear',
  REST_CHARGING_STATIONS_CHARGING_PROFILES_CLEAR = 'charging-stations/:id/charging-profiles/clear',
  REST_CHARGING_STATIONS_TRIGGER_DATA_TRANSFER = 'charging-stations/:id/data/transfer',
  REST_CHARGING_STATIONS_TRIGGER_MESSAGE = 'charging-stations/:id/trigger/message',
  REST_CHARGING_STATIONS_GET_INSTALLED_CERTIFICATE_IDS = 'charging-stations/:id/installed-certificate-ids/get',
  REST_CHARGING_STATIONS_DELETE_CERTIFICATE = 'charging-stations/:id/certificate/delete',
  REST_CHARGING_STATIONS_INSTALL_CERTIFICATE = 'charging-stations/:id/certificate/install',
  REST_CHARGING_STATIONS_SET_NETWORK_PROFILE = 'charging-stations/:id/network-profile/set',
  REST_CHARGING_STATIONS_RETRIEVE_CONFIGURATION = 'charging-stations/:id/configuration/retrieve',
  REST_CHARGING_STATIONS_CHANGE_CONFIGURATION = 'charging-stations/:id/configuration',
  REST_CHARGING_STATIONS_REMOTE_START = 'charging-stations/:id/remote/start',
  REST_CHARGING_STATIONS_REMOTE_STOP = 'charging-stations/:id/remote/stop',
  REST_CHARGING_STATIONS_UNLOCK_CONNECTOR = 'charging-stations/:id/connectors/:connectorId/unlock',
  REST_CHARGING_STATIONS_GET_COMPOSITE_SCHEDULE = 'charging-stations/:id/composite-schedule/get',
  REST_CHARGING_STATIONS_GET_DIAGNOSTICS = 'charging-stations/:id/diagnostics/get',
  REST_CHARGING_STATIONS_FIRMWARE_UPDATE = 'charging-stations/:id/firmware/update',
  REST_CHARGING_STATIONS_CHANGE_AVAILABILITY = 'charging-stations/:id/availability/change',
  REST_CHARGING_STATIONS_RESERVE_NOW = 'charging-stations/:id/reserve/now',
  REST_CHARGING_STATIONS_CANCEL_RESERVATION = 'charging-stations/:id/reservation/cancel',

  REST_CHARGING_STATIONS_DOWNLOAD_FIRMWARE = 'charging-stations/firmware/download',
  REST_CHARGING_STATIONS_QR_CODE_GENERATE = 'charging-stations/:id/connectors/:connectorId/qrcode/generate',
  REST_CHARGING_STATIONS_QR_CODE_DOWNLOAD = 'charging-stations/qrcode/download',

  REST_CHARGING_STATION_GET_OCPP_PARAMETERS = 'charging-stations/:id/ocpp/parameters',
  REST_CHARGING_STATIONS_REQUEST_OCPP_PARAMETERS = 'charging-stations/ocpp/parameters',
  REST_CHARGING_STATIONS_EXPORT_OCPP_PARAMETERS = 'charging-stations/ocpp/parameters/export',

  REST_CHARGING_STATIONS_UPDATE_PARAMETERS = 'charging-stations/:id/parameters',
  REST_CHARGING_STATIONS_POWER_LIMIT = 'charging-stations/:id/power/limit',
  REST_CHARGING_STATIONS_TRANSACTIONS = 'charging-stations/:id/transactions',
  REST_CHARGING_STATIONS_IN_ERROR = 'charging-stations/status/in-error',
  REST_CHARGING_STATIONS_EXPORT = 'charging-stations/action/export',
  REST_CHARGING_STATIONS_BOOT_NOTIFICATIONS = 'charging-stations/notifications/boot',
  REST_CHARGING_STATIONS_STATUS_NOTIFICATIONS = 'charging-stations/notifications/status',

  REST_CHARGING_STATIONS_GET_CERTIFICATES = 'charging-stations/:id/certificates',

  REST_CHARGING_PROFILES = 'charging-profiles',
  REST_CHARGING_PROFILE = 'charging-profiles/:id',

  REST_TRANSACTIONS = 'transactions',
  REST_TRANSACTIONS_IN_ERROR = 'transactions/status/in-error',
  REST_TRANSACTIONS_ACTIVE = 'transactions/status/active',
  REST_TRANSACTIONS_COMPLETED = 'transactions/status/completed',
  REST_TRANSACTION = 'transactions/:id',
  REST_TRANSACTIONS_EXPORT = 'transactions/action/export',
  REST_TRANSACTION_CDR_PUSH = 'transactions/:id/roaming/cdr/push',
  REST_TRANSACTION_CDR_EXPORT = 'transactions/:id/roaming/cdr/export',
  REST_TRANSACTION_CONSUMPTIONS = 'transactions/:id/consumptions',
  REST_TRANSACTION_CONSUMPTIONS_FOR_ADVENIR = 'transactions/:id/consumptions-for-advenir',
  REST_TRANSACTION_START_OLD = 'transactions/start', // Deprecated 06/06/2024 - Working App Mobile V3.0.12
  REST_TRANSACTION_START = 'transactions/action/start',
  REST_TRANSACTION_STOP = 'transactions/:id/stop',
  REST_TRANSACTION_SOFT_STOP = 'transactions/:id/soft-stop',
  REST_TRANSACTIONS_REFUND_ACTION = 'transactions/action/refund',
  REST_TRANSACTIONS_REFUND = 'transactions/status/refund',
  REST_TRANSACTIONS_REFUND_EXPORT = 'transactions/status/refund/export',
  REST_TRANSACTIONS_SYNCHRONIZE_REFUNDED = 'transactions/status/refund/synchronize',
  REST_TRANSACTIONS_REFUND_REPORTS = 'transactions/status/refund/reports',

  REST_USERS = 'users',
  REST_USER = 'users/:id',
  REST_USER_DEFAULT_TAG_CAR = 'users/:id/default-car-tag',
  REST_USER_TRANSACTION_PREREQUISITES = 'users/:id/transaction/prerequisites',
  REST_USER_SUBSCRIPTION_PREREQUISITES = 'users/:id/subscription/prerequisites',
  REST_USER_SITES = 'users/:id/sites',
  REST_USER_ASSIGN_SITES = 'users/:id/sites/assign',
  REST_USER_UNASSIGN_SITES = 'users/:id/sites/unassign',
  REST_USER_UPDATE_MOBILE_TOKEN = 'users/:id/mobile-token',
  REST_USER_UPDATE_MOBILE_DATA = 'users/:id/mobile-data',
  REST_USER_IMAGE = 'users/:id/image',
  REST_USERS_IN_ERROR = 'users/status/in-error',
  REST_USERS_IMPORT = 'users/action/import',
  REST_USERS_EXPORT = 'users/action/export',

  REST_SUBSCRIPTIONS = 'subscriptions',
  REST_SUBSCRIPTION = 'subscriptions/:id',

  REST_USER_SUBSCRIPTIONS = 'user-subscriptions/',
  REST_USER_SUBSCRIPTION = 'user-subscriptions/:id',

  REST_PLANNINGS = 'plannings',
  REST_PLANNING = 'plannings/:id',

  REST_USER_GROUPS = 'user-groups',
  REST_USER_GROUP = 'user-groups/:id',
  REST_USER_GROUP_ASSIGN_USERS = 'user-groups/:id/users/assign',
  REST_USER_GROUP_UNASSIGN_USERS = 'user-groups/:id/users/unassign',

  REST_TAGS = 'tags',
  REST_TAG = 'tags/:id',
  REST_TAG_ASSIGN = 'tags/:id/assign',
  REST_TAG_UNASSIGN = 'tags/:id/unassign',
  REST_TAGS_UNASSIGN = 'tags/unassign',
  REST_TAGS_IMPORT = 'tags/action/import',
  REST_TAGS_EXPORT = 'tags/action/export',

  REST_ASSETS = 'assets',
  REST_ASSET = 'assets/:id',
  REST_ASSETS_IN_ERROR = 'assets/status/in-error',
  REST_ASSET_CHECK_CONNECTION = 'assets/connectors/:id/connection/check',
  REST_ASSET_RETRIEVE_CONSUMPTION = 'assets/:id/connector/consumption/retrieve-last',
  REST_ASSET_CONSUMPTIONS = 'assets/:id/consumptions',
  REST_ASSET_IMAGE = 'assets/:id/image',

  REST_CARS = 'cars',
  REST_CAR = 'cars/:id',
  REST_CAR_CATALOGS = 'car-catalogs',
  REST_CAR_CATALOG = 'car-catalogs/:id',
  REST_CAR_CATALOG_IMAGES = 'car-catalogs/:id/images',
  REST_CAR_CATALOG_IMAGE = 'car-catalogs/:id/image',
  REST_CAR_CATALOG_SYNCHRONIZE = 'car-catalogs/action/synchronize',
  REST_CAR_MAKERS = 'car-makers',

  REST_PING = 'ping',

  REST_TENANTS = 'tenants',
  REST_TENANT = 'tenants/:id',
  REST_TENANT_LOGO_BY_SUBDOMAIN = 'tenants/logo',
  REST_TENANT_LOGO = 'tenants/:id/logo',
  REST_TENANT_QR_CODE_GENERATE = 'tenants/:id/qrcode/generate',
  REST_TENANT_QR_CODE_DOWNLOAD = 'tenants/:id/qrcode/download',
  REST_TENANT_PUBLIC_INFO = 'tenants/public-info',

  REST_COMPANIES = 'companies',
  REST_COMPANY = 'companies/:id',
  REST_COMPANY_LOGO = 'companies/:id/logo',

  REST_CONNECTIONS = 'connections',
  REST_CONNECTION = 'connections/:id',

  REST_LOGS = 'logs',
  REST_LOG = 'logs/:id',
  REST_LOGS_EXPORT = 'logs/action/export',

  REST_NOTIFICATIONS = 'notifications',
  REST_NOTIFICATIONS_END_USER_REPORT_ERROR = 'notifications/action/end-user/report-error',

  REST_OCPI_ENDPOINT_PING = 'ocpi/endpoints/:id/ping',
  REST_OCPI_ENDPOINT_CHECK_CDRS = 'ocpi/endpoints/:id/cdrs/check',
  REST_OCPI_ENDPOINT_CHECK_LOCATIONS = 'ocpi/endpoints/:id/locations/check',
  REST_OCPI_ENDPOINT_CHECK_SESSIONS = 'ocpi/endpoints/:id/sessions/check',
  REST_OCPI_ENDPOINT_PULL_CDRS = 'ocpi/endpoints/:id/cdrs/pull',
  REST_OCPI_ENDPOINT_PULL_LOCATIONS = 'ocpi/endpoints/:id/locations/pull',
  REST_OCPI_ENDPOINT_PULL_SESSIONS = 'ocpi/endpoints/:id/sessions/pull',
  REST_OCPI_ENDPOINT_PULL_TOKENS = 'ocpi/endpoints/:id/tokens/pull',
  REST_OCPI_ENDPOINT_SEND_EVSE_STATUSES = 'ocpi/endpoints/:id/evses/statuses/send',
  REST_OCPI_ENDPOINT_SEND_TOKENS = 'ocpi/endpoints/:id/tokens/send',
  REST_OCPI_ENDPOINT_GENERATE_LOCAL_TOKEN = 'ocpi/endpoints/tokens/generate',
  REST_OCPI_ENDPOINTS = 'ocpi/endpoints',
  REST_OCPI_ENDPOINT = 'ocpi/endpoints/:id',
  REST_OCPI_ENDPOINT_REGISTER = 'ocpi/endpoints/:id/register',
  REST_OCPI_ENDPOINT_UNREGISTER = 'ocpi/endpoints/:id/unregister',
  REST_OCPI_ENDPOINT_CREDENTIALS = 'ocpi/endpoints/:id/credentials',

  REST_OICP_ENDPOINTS = 'oicp/endpoints',
  REST_OICP_ENDPOINT = 'oicp/endpoints/:id',
  REST_OICP_ENDPOINT_PING = 'oicp/endpoints/:id/ping',
  REST_OICP_ENDPOINT_SEND_EVSE_STATUSES = 'oicp/endpoints/:id/evses/statuses/send',
  REST_OICP_ENDPOINT_SEND_EVSES = 'oicp/endpoints/:id/evses/send',

  REST_SETTINGS = 'settings',
  REST_SETTING = 'settings/:id',
  REST_SETTINGS_CHECK_GRID_MONITORING_CONNECTION = 'settings/grid-monitoring/connection/check',
  REST_SETTINGS_CHECK_SMART_CHARGING_CONNECTION = 'settings/smart-charging/connection/check',

  REST_REGISTRATION_TOKENS = 'registration-tokens',
  REST_REGISTRATION_TOKEN = 'registration-tokens/:id',
  REST_REGISTRATION_TOKEN_REVOKE = 'registration-tokens/:id/revoke',

  REST_SITE_AREAS = 'site-areas',
  REST_SITE_AREA = 'site-areas/:id',
  REST_SITE_AREA_CONSUMPTION = 'site-areas/:id/consumptions',
  REST_SITE_AREA_IMAGE = 'site-areas/:id/image',
  REST_SITE_AREA_ASSIGN_CHARGING_STATIONS = 'site-areas/:id/charging-stations/assign',
  REST_SITE_AREA_UNASSIGN_CHARGING_STATIONS = 'site-areas/:id/charging-stations/unassign',
  REST_SITE_AREA_ASSIGN_ASSETS = 'site-areas/:id/assets/assign',
  REST_SITE_AREA_UNASSIGN_ASSETS = 'site-areas/:id/assets/unassign',
  REST_SITE_AREA_TRIGGER_SMART_CHARGING = 'site-areas/smart-charging/trigger',
  REST_SITE_AREA_TRIGGER_GRID_MONITORING = 'site-areas/grid-monitoring/trigger',

  REST_GUESTS_REGISTER = 'guests/register',
  REST_GUESTS_CHECK_CHARGING_STATION = 'guests/charging-station/check',
  REST_GUESTS_VERIFY_EMAIL = 'guests/email/verify',
  REST_GUESTS_CREATE_PAYMENT = 'guests/payment/create',
  REST_GUESTS_CONFIRM_PAYMENT = 'guests/payment/confirm',
  REST_GUESTS_START_TRANSACTION = 'guests/transaction/start',
  REST_GUESTS_STOP_TRANSACTION = 'guests/transaction/stop',

  REST_SITES = 'sites',
  REST_SITE = 'sites/:id',
  REST_SITE_USERS = 'sites/:id/users',
  REST_SITE_ASSIGN_USERS = 'sites/:id/users/assign',
  REST_SITE_UNASSIGN_USERS = 'sites/:id/users/unassign',
  REST_SITE_ADMIN = 'sites/:id/users/admin',
  REST_SITE_OWNER = 'sites/:id/users/owner',
  REST_SITE_IMAGE = 'sites/:id/image',

  REST_STATISTICS_EXPORT = 'statistics/action/export',
  REST_CHARGING_STATION_CONSUMPTION_STATISTICS = 'statistics/charging-stations/consumption',
  REST_CHARGING_STATION_USAGE_STATISTICS = 'statistics/charging-stations/usage',
  REST_CHARGING_STATION_INACTIVITY_STATISTICS = 'statistics/charging-stations/inactivity',
  REST_CHARGING_STATION_TRANSACTIONS_STATISTICS = 'statistics/charging-stations/transaction',
  REST_CHARGING_STATION_PRICING_STATISTICS = 'statistics/charging-stations/pricing',
  REST_USER_CONSUMPTION_STATISTICS = 'statistics/users/consumption',
  REST_USER_USAGE_STATISTICS = 'statistics/users/usage',
  REST_USER_INACTIVITY_STATISTICS = 'statistics/users/inactivity',
  REST_USER_TRANSACTIONS_STATISTICS = 'statistics/users/transaction',
  REST_USER_PRICING_STATISTICS = 'statistics/users/pricing',
  REST_TRANSACTION_YEARS = 'statistics/transactions/years',

  // BILLING URLs for CRUD operations on PAYMENT METHODS
  REST_BILLING_PAYMENT_METHODS = 'users/:userID/payment-methods',
  REST_BILLING_PAYMENT_METHOD = 'users/:userID/payment-methods/:paymentMethodID',

  // BILLING URLs for Non-CRUD Operations on PAYMENT METHODS
  REST_BILLING_PAYMENT_METHOD_CREATE = 'users/:userID/payment-methods/setup',
  REST_BILLING_PAYMENT_METHOD_SET_DEFAULT = 'users/:userID/payment-methods/:paymentMethodID/set-default',
  REST_BILLING_PAYMENT_METHOD_ATTACH = 'users/:userID/payment-methods/:paymentMethodID/attach',
  REST_BILLING_PAYMENT_METHOD_DETACH = 'users/:userID/payment-methods/:paymentMethodID/detach',

  REST_BILLING_FORCE_SYNC_USER = 'billing/users/:userID/synchronize',

  REST_BILLING_SETTING = 'billing-setting', // GET and PUT
  REST_BILLING_CHECK = 'billing/check',
  REST_BILLING_CLEAR_TEST_DATA = 'billing/clear-test-data',

  REST_BILLING_TAXES = 'billing/taxes',

  REST_BILLING_ACCOUNTS = 'billing/accounts',
  REST_BILLING_ACCOUNT = 'billing/accounts/:id',
  REST_BILLING_ACCOUNT_ONBOARD = 'billing/accounts/:id/onboard',
  REST_BILLING_ACCOUNT_REFRESH = 'billing/accounts/:id/refresh',
  REST_BILLING_ACCOUNT_ACTIVATE = 'billing/accounts/:id/activate',

  // BILLING URLs for CRUD operations on INVOICES
  REST_BILLING_INVOICES = 'invoices',
  REST_BILLING_INVOICE = 'invoices/:invoiceID',

  // BILLING URLs for Non-CRUD operations on INVOICES
  REST_BILLING_DOWNLOAD_INVOICE = 'invoices/:invoiceID/download',
  REST_BILLING_SYNC_INVOICE = 'invoices/action/synchronize',

  REST_BILLING_TRANSFERS = 'billing/transfers',
  REST_BILLING_TRANSFER = 'billing/transfers/:id',
  REST_BILLING_TRANSFER_FINALIZE = 'billing/transfers/:id/finalize',
  REST_BILLING_TRANSFER_SEND = 'billing/transfers/:id/send',
  REST_BILLING_TRANSFER_DOWNLOAD_INVOICE = 'billing/transfers/:id/invoice/download',

  // PRICING URLs for CRUD operations
  REST_PRICING_DEFINITIONS = 'pricing-definitions',
  REST_PRICING_DEFINITION = 'pricing-definitions/:id',

  // PRICING URLs for Non-CRUD operations
  REST_PRICING_MODEL_RESOLVE = 'pricing-model/resolve',

  REST_SETTINGS_CHECK_PLUG_AND_CHARGE_CONNECTION = 'plug-and-charge/connectors/:id/connection/check',

  REST_SCHEDULER_TASKS = 'scheduler-tasks',

  REST_BLACKLISTS = 'blacklists',
  REST_BLACKLIST = 'blacklists/:id'
}

export enum OCPIServerRoute {
  OCPI_CREDENTIALS = 'credentials',
  OCPI_LOCATIONS = 'locations',
  OCPI_TOKENS = 'tokens',
  OCPI_SESSIONS = 'sessions',
  OCPI_CDRS = 'cdrs',
  OCPI_COMMANDS = 'commands',
  OCPI_TARIFFS = 'tariffs',
  OCPI_VERSIONS = 'versions',
}

export enum OCPIServerRouteVersions {
  VERSION_211 = '2.1.1'
}

export enum OICPServerRouteVersions {
  VERSION_230 = '2.3.0'
}

export enum OICPServerRoute {
  OICP_VERSIONS = 'versions',
  OICP_AUTHORIZE_REMOTE_START = 'providers/:providerId/authorize-remote/start',
  OICP_AUTHORIZE_REMOTE_STOP = 'providers/:providerId/authorize-remote/stop'
}

export enum ServerProtocol {
  HTTP = 'http',
  HTTPS = 'https',
  WS = 'ws',
  WSS = 'wss'
}

export enum ServerRole {
  REST_SERVER = 'Rest',
  OCPP_SOAP_SERVER = 'OcppSoap',
  OCPP_JSON_SERVER = 'OcppJson',
  OCPI_SERVER = 'Ocpi',
  OICP_SERVER = 'Oicp',
  ODATA_SERVER = 'OData',
  BATCH_SERVER = 'Batch',
  CENTRAL_SERVER = 'Central',
  PROMETHEUS_SERVER = 'Prometheus',
  ALL_SERVERS = 'All',
}

export enum WSServerProtocol {
  OCPP16 = 'ocpp1.6',
  OCPP201 = 'ocpp2.0.1',
  REST = 'rest'
}
